import { useState, useEffect, useRef, memo } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { useLocation } from '@reach/router'
import ReactTooltip from "react-tooltip";
import { FixedSizeList as List, areEqual } from "react-window";
import uniqBy from 'lodash/uniqBy'

import { formatDate } from "../../../config/i18n";
import useStore from "../useStore";
import useQuery from "hooks/useQuery";
import useUser from "hooks/useUser";
import useSocket from "hooks/useSocket";
import Event from "./Event/Event";
import Print from "./Print";
import Calendar from "../Sidebar/Calendar";
import styles from "./styles.module.scss";

export const sort = (a, b) => {
  if (a.status === "arrived" && b.status !== "arrived") return -1;
  if (a.status !== "arrived" && b.status === "arrived") return 1;
  if (a.status === "active" && b.status !== "active") return -1;
  if (a.status !== "active" && b.status === "active") return 1;
  if (
    ["fulfilled", "cancelled", "noshow"].includes(a.status) &&
    !["fulfilled", "cancelled", "noshow"].includes(b.status)
  )
    return 1;
  if (
    !["fulfilled", "cancelled", "noshow"].includes(a.status) &&
    ["fulfilled", "cancelled", "noshow"].includes(b.status)
  )
    return -1;
  const aDatetime = `${a.day}T${a.time}`
  const bDatetime = `${b.day}T${b.time}`
  if (aDatetime < bDatetime) return -1;
  if (aDatetime > bDatetime) return 1;
  return 0
};

const Loader = () => {
  const { t } = useTranslation();
  return (
    <div
      className={`d-flex flex-column align-items-center justify-content-center p-5`}
    >
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">
          {t("loading", { context: "slot" })}
        </span>
      </div>
    </div>
  );
};

const Row = memo((props) => {
  const { index, style, data } = props;

  return (
    <div style={style}>
      <Event event={data[index]} />
    </div>
  );
}, areEqual);

const Dropdown = ({ date }) => {
  return (
    <div className="dropdown">
      <button data-bs-toggle="dropdown" className="text-first-up dropdown-toggle btn btn-lg text-start p-0 flex-fill text-truncate">
        {formatDate(date, "eeee d - MMMM yyyy")}
      </button>
      <div className="dropdown-menu p-0" style={{ width: '280px', maxWidth: '280px', minWidth: '280px' }}>
        <Calendar />
      </div>
    </div>
  )
}

function Agenda(props) {
  const { t } = useTranslation();
  const store = useStore(state => ({
    location: state.location?.id,
    resource: state.resource?.id,
    update: state.update,
  }));
  const user = useUser();
  const [dimensions, setDimensions] = useState({});
  const $container = useRef(null);
  const socket = useSocket()
  const [events, setEvents] = useState({})
  const [data, setData] = useState([])
  const location = useLocation()

  const [status, setStatus] = useState();

  const date = new Date(`${props.date}T00:00`)
  const filters = {}

  filters.events = {
    cancelled: true,
    noshow: true,
    type: "appointment",
    startsAt: date.getTime(),
    endsAt: date.getTime() + (24 * 60 * 60 * 1000 - 1),
    resource: store.resource,
    location: store.location,
    // service: store.service,
    // financier: store.financier,
  };

  const query = useQuery(window._l(`/events/query`).substr(1), {
    body: filters.events,
    initialData: []
  });

  filters.availability = {
    startsAt: new Date().setHours(0, 0, 0, 0),
    endsAt: new Date().setHours(23, 59, 59, 999),
    resource: store.resource,
    location: store.location,
    free: false
  };

  const availability = useQuery(window._l(`/availability`).substr(1), {
    body: filters.availability,
  });

  const keys = [
    "all",
    "booked",
    "arrived",
    "active",
    "fulfilled",
    "noshow",
    "cancelled",
    "pending",
  ];


  useEffect(() => {
    ReactTooltip.rebuild();
  });

  useEffect(() => {
    if (
      !dimensions.ready &&
      Array.isArray(query.data) &&
      query.data.filter((e) => !status || e.status === status).length
    ) {
      setDimensions({
        height: $container.current?.offsetHeight,
        width: $container.current?.offsetWidth,
        ready:
          !!$container.current?.offsetHeight ||
          !!$container.current?.offsetWidth,
      });
    }
  }, [$container.current, JSON.stringify(dimensions), query.data, status]);

  useEffect(() => {
    const { facets } = availability.data || {}
    store.update({ facets })
  }, [availability.data]);

  useEffect(() => {
    const onResize = () => {
      setDimensions({
        height: $container.current?.offsetHeight,
        width: $container.current?.offsetWidth,
        ...dimensions,
      });
    }
    onResize()
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  useEffect(() => {
    const callback = (data) => {
      if (location.pathname.includes(data.teamID)) {
        events[data.id] = data
        setEvents(JSON.clone(events))
      }
    }
    if (socket) socket.on('events', callback)
    return () => socket?.off('events', callback)
  }, [socket, props.date, events, location.pathname])

  useEffect(() => {
    let data = {}
    if (Array.isArray(query.data)) {
      for (const event of query.data) {
        data[event.id] = { ...event }
      }
      for (const event of Object.values(events)) {
        data[event.id] = { ...event }
      }

      data = Object.values(data).filter((e) =>
        e.day === props.date &&
        (!status || e.status === status) &&
        (!store.location || e.location?.id === store.location) &&
        (!store.resource || e.resource?.id === store.resource)
      ).sort(sort);

      setData(uniqBy(data, 'id'))
    }
  }, [events, query.data, props.date, status, store.location, store.resource])

  if (!user.state.uid || !Array.isArray(query.data)) return <Loader />;

  return  (
    <div className="mvh-100-1">
      <div className="mb-1 ms-1">
        <div className="bg-white vw-100 d-lg-flex align-items-lg-center shadow-sm rounded px-2 px-lg-3 py-2">
          <Dropdown date={props.date} />
          {query.data.length > 0 && (
            <div className="overflow-auto d-flex flex-nowrap align-items-center gap-3 ps-2">
              {keys.map((key) => (
                <button
                  key={`tab-${key}`}
                  className={cx("btn btn-sm text-nowrap", {
                    "link-primary": key === "all" ? !status : status === key,
                    "text-muted": key === "all" ? !!status : status !== key,
                  })}
                  onClick={() => setStatus(key === "all" ? undefined : key)}
                >
                  {t(key, { context: "agenda" })}
                  <sup className="ms-1">
                    {
                      query.data.filter(
                        (event) => key === "all" || key === event.status
                      ).length
                    }
                  </sup>
                </button>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className={`overflow bg-white-auto ms-1 ${styles.container}`} ref={$container}>
        {query.isFetching ? (
          <div className="mvh-100-1 d-flex flex-column justify-content-center align-items-center">
            <Loader />
          </div>
        ) : query.data.length > 0 && dimensions.height > 0 && dimensions.width > 0 ? (
          <List
            height={dimensions.height}
            itemCount={data.length}
            itemData={data}
            itemSize={100}
            width={dimensions.width}
          >
            {Row}
          </List>
        ) : (
          <div className="mvh-100-1 d-flex flex-column justify-content-center align-items-center">
            <div className="p-3 alert alert-warning mx-1">
              <h6 className="m-0">{t("no_events", 'No hay turnos', { context: "agenda" })}</h6>
            </div>
          </div>
        )}
      </div>
      {query.data?.length && data.length && <Print events={data} date={formatDate(props.date, "eeee d - MMMM yyyy")} />}
    </div>
  );
}

export default Agenda;
