import { useEffect } from 'react'
import { useLocation } from "@reach/router";
import ReactCalendar from "react-calendar";

import useQuery from "hooks/useQuery";
import useUser from "hooks/useUser";
import useStore from "../useStore";

const Calendar = () => {
  const location = useLocation();
  const user = useUser();
  const store = useStore((state) => ({
    filters: {
      resource: state.resource?.id,
      location: state.location?.id,
    },
    date: state.date,
    update: state.update
  }));

  const stats = useQuery(window._l(`/stats`).substr(1), {
    body: { ...store.filters },
    initialData: { events: {}, resources: {} },
  });

  const onClickDay = (value, event) => {
    const date = value.toLocaleDateString('fr-ca');
    store.update({ date, updatedAt: Date.now() })
  };

  return (
    <div className="mb-1">
      <ReactCalendar
        locale={user.state.language}
        next2Label={null}
        prev2Label={null}
        calendarType="ISO 8601"
        defaultValue={new Date(`${store.date}T00:00`)}
        onClickDay={onClickDay}
        minDetail="year"
        showFixedNumberOfWeeks
        formatDay={(locale, date) => date.getDate().toString()}
        tileClassName={({ activeStartDate, date, view }) => {
          if (!stats?.data?.events) return ""
          try {
            const day = `${date.getFullYear()}-${(date.getMonth() + 1)
              .toString()
              .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
            const stat = stats.data.events[day];
            if (view === "month" && stat) return "highlight";
          } catch {}
          return "";
        }}
      />
    </div>
  );
};

export default Calendar;
